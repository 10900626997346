import React, { useEffect, useState } from "react";
import { useNavigate, useParams,Link } from "react-router-dom";
import axios from "axios";
import { GetSingleMapHistoricalDataURL } from "../assest/api/BaseURL";
import { CSVLink } from "react-csv";
import SideBar from "./SideBar";

const MapHistorical = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const getMapHistoricalData = async () => {
        try {
          const response = await axios.get(
            GetSingleMapHistoricalDataURL + `?map_search_id=${id}`
          );
          
          if (response.status === 200) {
            setSearchData(response.data.data);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getMapHistoricalData();
    }
  }, [id]);

  return (
    <>
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large uk-grid">
              <SideBar active="google_map" />
              <div className="uk-width-expand@m content_div">
                <div className="sub_container">
                  <div className="input_container">
                    {searchData.length > 0 && (
                      <button className="btn_export">
                        <CSVLink
                          className="csv_link"
                          data={searchData}
                          filename={"historical-map-data-result.csv"}
                          headers={[
                            { label: "Search Id", key: "map_result_id" },
                            { label: "Name", key: "name" },
                            { label: "Rating", key: "reviews" },
                            { label: "Website", key: "website" },
                            { label: "Contact No.", key: "mobile" },
                            { label: "Address", key: "address" },
                          ]}
                        >
                          Export To CSV
                        </CSVLink>
                      </button>
                    )}
                    <button
                      className="btn_data"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/map_search");
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                </div>

                <div className="content">
                  <div className="contentdiv">
                    <div className="data_container">
                      {loading && (
                        <div className="title_desc">
                          <div
                            className="title"
                            style={{ textAlign: "center" }}
                          >
                            <h1>Loading ...</h1>
                          </div>
                        </div>
                      )}

                      {searchData.length !== 0 && (
                        <table className="uk-table uk-table-divider uk-table-striped tablecss trgreen">
                          <thead>
                            <tr>
                              <th style={{ width: "5%" }}>S.No</th>
                              <th style={{ width: "25%" }}>Name</th>
                              <th style={{ width: "10%" }}>Rating</th>
                              <th style={{ width: "15%" }}>website</th>
                              <th style={{ width: "15%" }}>Mobile No.</th>
                              <th style={{ width: "30%" }}>Address</th>
                            </tr>
                          </thead>

                          <tbody>
                            {searchData &&
                              searchData.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.name}</td>
                                  <td>{item.reviews}</td>
                                  <td>
                                    <Link to={item.website} target="_blank">
                                      {item.website}
                                    </Link>
                                  </td>
                                  <td>{item.mobile}</td>
                                  <td>{item.address}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapHistorical;
