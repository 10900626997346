import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { GetSingleHistoricalDataURL } from "../assest/api/BaseURL";
import { CSVLink } from "react-csv";
import SideBar from "./SideBar";

const Historical = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      const getHistoricalData = async () => {
        try {
          const response = await axios.get(
            GetSingleHistoricalDataURL + `?search_term_id=${id}`
          );

          if (response.status === 200) {
            setSearchData(response.data.data);
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getHistoricalData();
    }
  }, [id]);

  return (
    <>
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large uk-grid">
              <SideBar active="google_search" />
              <div className="uk-width-expand@m content_div">
                <div className="sub_container">
                  <div className="input_container">
                    {searchData.length > 0 && (
                      <button className="btn_export">
                        <CSVLink
                          className="csv_link"
                          data={searchData}
                          filename={"historical-data-result.csv"}
                          headers={[
                            { label: "Search Id", key: "search_id" },
                            { label: "Name", key: "title" },
                            { label: "Link", key: "link" },
                            { label: "Description", key: "description" },
                            { label: "Searched At", key: "created_at" },
                          ]}
                        >
                          Export To CSV
                        </CSVLink>
                      </button>
                    )}
                    <button
                      className="btn_data"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/");
                      }}
                    >
                      Go Back
                    </button>
                  </div>
                </div>

                <div className="content">
                  <div className="contentdiv">
                    <div className="data_container">
                      {loading && (
                        <div className="title_desc">
                          <div
                            className="title"
                            style={{ textAlign: "center" }}
                          >
                            <h1>Loading ...</h1>
                          </div>
                        </div>
                      )}
                      {searchData &&
                        searchData.map((data) => (
                          <div
                            key={data.search_id}
                            className="data_card_container"
                          >
                            <div className="title_desc">
                              <div className="title">
                                <a
                                  href={data.link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {data.title}
                                </a>
                                <br />
                                <span className="date_time">
                                  Searched At :{" "}
                                  {new Date(data.created_at).toLocaleString(
                                    "en-IN",
                                    {
                                      timeZone: "Asia/Kolkata",
                                    }
                                  )}
                                </span>
                                <p>{data.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Historical;
