import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import { GetHistoricalDataURL, SearchURL } from "../assest/api/BaseURL";
import SideBar from "./SideBar";

const Home = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchClick = async () => {
    setLoading(true);
    setSearchData([]);
    setHistoricalData([]);
    try {
      const response = await axios.get(SearchURL + `?q=${searchValue}`);

      if (response.status === 200) {
        setSearchData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleShowHistoricalData = async () => {
    setLoading(true);
    setSearchData([]);
    setHistoricalData([]);
    try {
      const response = await axios.get(GetHistoricalDataURL);

      if (response.status === 200) {
        setHistoricalData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSearchItemClick = (id) => {
    navigate(`/historical/${id}`);
  };

  return (
    <>
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large uk-grid">
              <SideBar active="google_search" />
              <div className="uk-width-expand@m content_div">
                <div className="sub_container">
                  <div className="input_container">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      onKeyDown={handleKeyPress}
                    />
                    <button
                      className="btn_search"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSearchClick();
                      }}
                    >
                      Search
                    </button>
                    <button
                      className="btn_data"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShowHistoricalData();
                      }}
                    >
                      Show Historical Data
                    </button>
                    {searchData.length > 0 && (
                      <button className="btn_export">
                        <CSVLink
                          className="csv_link"
                          data={searchData}
                          filename={"search-data.csv"}
                          headers={[
                            { label: "Name", key: "title" },
                            { label: "Link", key: "link" },
                            { label: "Description", key: "description" },
                          ]}
                        >
                          Export To CSV
                        </CSVLink>
                      </button>
                    )}
                    {historicalData.length > 0 && (
                      <button className="btn_export">
                        <CSVLink
                          className="csv_link"
                          data={historicalData}
                          filename={"historical-data.csv"}
                          headers={[
                            { label: "Search Id", key: "search_term_id" },
                            { label: "Search Item", key: "search_item" },
                            { label: "Searched At", key: "created_at" },
                          ]}
                        >
                          Export To CSV
                        </CSVLink>
                      </button>
                    )}
                  </div>
                </div>

                <div className="content">
                  <div className="contentdiv">
                    <div className="data_container">
                      {loading && (
                        <div className="title_desc">
                          <div
                            className="title"
                            style={{ textAlign: "center" }}
                          >
                            <h1>Loading ...</h1>
                          </div>
                        </div>
                      )}
                      {searchData &&
                        searchData.map((data, index) => (
                          <div key={index} className="data_card_container">
                            <div className="title_desc">
                              <div className="title">
                                <a
                                  href={data.link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {data.title}
                                </a>
                                <p>{data.description}</p>
                              </div>
                            </div>
                          </div>
                        ))}

                      {historicalData &&
                        historicalData.map((item) => (
                          <div
                            key={item.search_term_id}
                            className="historical_data_card_container"
                          >
                            <div className="uk-grid" uk-grid="">
                              <div className="uk-width-expand uk-text-left">
                                <div className="title_desc">
                                  <div className="title">
                                    <Link
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleSearchItemClick(
                                          item.search_term_id
                                        );
                                      }}
                                    >
                                      {item.search_item}{" "}
                                    </Link>
                                    <br />
                                    <span className="date_time">
                                      Searched At :{" "}
                                      {new Date(item.created_at).toLocaleString(
                                        "en-IN",
                                        {
                                          timeZone: "Asia/Kolkata",
                                        }
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
