import React from "react";
import { Link, useNavigate } from "react-router-dom";
import CompanyLogo from "../../images/clogo.png";

const SideBar = ({ active }) => {
  const navigate = useNavigate();

  const handleGoogleSearchClick = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const handleMapSearchClick = (e) => {
    e.preventDefault();
    navigate("/map_search");
  };

  return (
    <>
      <div className="sidebar uk-visible">
        <div className="logodiv">
          <span>
            <Link>
              <img src={CompanyLogo} className="sampleimg" alt="Company Logo" />
            </Link>
          </span>
        </div>

        <div className="menu">
          <ul className="uk-nav-default uk-list menuli uk-nav" uk-nav="">
            <li className="listcss">
              <Link
                className={
                  active === "google_search"
                    ? "sideancor currentli"
                    : "sideancor"
                }
                onClick={handleGoogleSearchClick}
              >
                Google Search
              </Link>
            </li>
            <li className="listcss">
              <Link
                className={
                  active === "google_map" ? "sideancor currentli" : "sideancor"
                }
                onClick={handleMapSearchClick}
              >
                Map Search
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default SideBar;
