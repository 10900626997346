import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./components/pages/Home";
import Historical from "./components/pages/Historical";
import MapSearch from "./components/pages/MapSearch";
import MapHistorical from "./components/pages/MapHistorical";

function App() {
  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/historical/:id" element={<Historical />}></Route>
            <Route path="/map_search" element={<MapSearch />}></Route>
            <Route path="/map_historical/:id" element={<MapHistorical />}></Route>
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
