import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { CSVLink } from "react-csv";
import { GetMapHistoricalDataURL, MapSearchURL } from "../assest/api/BaseURL";
import SideBar from "./SideBar";

const MapSearch = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearchClick = async () => {
    setLoading(true);
    setSearchData([]);
    setHistoricalData([]);
    try {
      const response = await axios.get(
        MapSearchURL +
          `?query=${searchValue}&city=${cityValue}&country=${countryValue}`
      );
      
      if (response.status === 200) {
        setSearchData(response.data.data);
      }
    } catch (error) {
      if (error.response.data.message) {
        alert(error.response.data.message);
      }
      console.log(error);
    }
    setLoading(false);
  };

  const handleShowHistoricalData = async () => {
    setLoading(true);
    setSearchData([]);
    setHistoricalData([]);
    try {
      const response = await axios.get(GetMapHistoricalDataURL);
      
      if (response.status === 200) {
        setHistoricalData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleSearchItemClick = (id) => {
    navigate(`/map_historical/${id}`);
  };

  return (
    <>
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large uk-grid">
              <SideBar active="google_map" />
              <div className="uk-width-expand@m content_div">
                <div className="sub_container">
                  <div className="input_container">
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Enter City Name"
                      value={cityValue}
                      onChange={(e) => setCityValue(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder="Enter Country Name"
                      value={countryValue}
                      onChange={(e) => setCountryValue(e.target.value)}
                    />
                    <button
                      className="btn_search"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSearchClick();
                      }}
                    >
                      Search
                    </button>
                    <button
                      className="btn_data"
                      onClick={(e) => {
                        e.preventDefault();
                        handleShowHistoricalData();
                      }}
                    >
                      Show Historical Data
                    </button>
                    {searchData.length > 0 && (
                      <button className="btn_export">
                        <CSVLink
                          className="csv_link"
                          data={searchData}
                          filename={"map-search-data.csv"}
                          headers={[
                            { label: "Name", key: "name" },
                            { label: "Rating", key: "reviews" },
                            { label: "Website", key: "website" },
                            { label: "Contact No.", key: "mobile" },
                            { label: "Address", key: "address" },
                          ]}
                        >
                          Export To CSV
                        </CSVLink>
                      </button>
                    )}
                    {historicalData.length > 0 && (
                      <button className="btn_export">
                        <CSVLink
                          className="csv_link"
                          data={historicalData}
                          filename={"historical-map-data.csv"}
                          headers={[
                            { label: "Search Id", key: "map_search_id" },
                            { label: "Search Item", key: "map_search_item" },
                            { label: "City", key: "map_search_city" },
                            { label: "Country", key: "map_search_country" },
                            { label: "Searched At", key: "created_at" },
                          ]}
                        >
                          Export To CSV
                        </CSVLink>
                      </button>
                    )}
                  </div>
                </div>

                <div className="content">
                  <div className="contentdiv">
                    <div className="data_container">
                      {loading && (
                        <div className="title_desc">
                          <div
                            className="title"
                            style={{ textAlign: "center" }}
                          >
                            <h1>Loading ...</h1>
                          </div>
                        </div>
                      )}

                      {searchData.length !== 0 && (
                        <table className="uk-table uk-table-divider uk-table-striped tablecss trgreen">
                          <thead>
                            <tr>
                              <th style={{ width: "5%" }}>S.No</th>
                              <th style={{ width: "25%" }}>Name</th>
                              <th style={{ width: "10%" }}>Rating</th>
                              <th style={{ width: "15%" }}>Website</th>
                              <th style={{ width: "15%" }}>Contact No.</th>
                              <th style={{ width: "30%" }}>Address</th>
                            </tr>
                          </thead>

                          <tbody>
                            {searchData &&
                              searchData.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.name}</td>
                                  <td>{item.reviews}</td>
                                  <td>
                                    <Link to={item.website} target="_blank">
                                      {item.website}
                                    </Link>
                                  </td>
                                  <td>{item.mobile}</td>
                                  <td>{item.address}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      )}

                      {historicalData &&
                        historicalData.map((item) => (
                          <div
                            key={item.map_search_id}
                            className="historical_map_data_container"
                          >
                            <div className="uk-grid" uk-grid="">
                              <div className="uk-width-expand uk-text-left">
                                <div className="title_desc">
                                  <div className="title">
                                    <Link
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleSearchItemClick(
                                          item.map_search_id
                                        );
                                      }}
                                    >
                                      {item.map_search_item}{" "}
                                    </Link>
                                    <br />
                                    <span>
                                      City : {item.map_search_city}
                                      {","}
                                    </span>{" "}
                                    <span>
                                      Country : {item.map_search_country}
                                    </span>{" "}
                                    <br />
                                    <span className="date_time">
                                      Searched At :{" "}
                                      {new Date(item.created_at).toLocaleString(
                                        "en-IN",
                                        {
                                          timeZone: "Asia/Kolkata",
                                        }
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapSearch;
